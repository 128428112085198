import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { OverlayService } from 'src/app/core/services/overlay.service';

export interface ProcessoAuditoria {
  id: number;
  agencia_id: number;
  agencia_nome: string;
  municipio_id: number;
  municipio_nome: string;
  prestador_id: number;
  prestador_nome: string;
  ano_id: number;
  ano_nome: string;
  status: string;
  status_desc: string;
  data_hora_inicio: string;
  dara_hora_inicio_teste_substantivo: string;
  dara_hora_fim_teste_substantivo: string;
  possui_auditor_interno: boolean;
  data_limite_envio_solicitacao_informacao: string;
}

export interface CtsProcessoAuditoria {
  auditoria_teste_controle_id: number;
  sigla: string;
  selecionado: boolean;
}

export interface InfoProcessoAuditoria {
  solicitacao_informacao: any[];
  teste_controle: any[];
  teste_substantivo: any[];
  solicitacao_informacao_processo: any[];
  teste_controle_info_grafico: any[];
}

export interface RelatorioGeralEstatisticaAuditoria {
  agencia_reguladora_nome: string;
  municipio_nome: string;
  prestador_nome: string;
  ano_desc: string;
  processo_auditoria_id: string;
  dados_solicitacao_grafico_geral: any[];
  dados_solicitacao_processo: any[];
}

@Injectable({
  providedIn: 'root'
})
export class ProcessoAuditoriaService {

  constructor(
    private apiService: ApiService,
    private overlayService: OverlayService
  ) { }

  async getRelatorioGeralEstatisticaAuditoria(): Promise<RelatorioGeralEstatisticaAuditoria[]> {
    const loading = await this.overlayService.loading({
      message: "Carregando registros..."
    });
    return new Promise((resolve, reject) => {
      this.apiService.syncData(
        'get_relatorio_geral_estatistica_auditoria'
      ).subscribe(
        (retorno: RelatorioGeralEstatisticaAuditoria[]) => {
          loading.dismiss();
          resolve(retorno);
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          );
          loading.dismiss();
          reject();
        }
      )
    });
  }

  async getAllProcessoAuditoria(): Promise<ProcessoAuditoria[]> {
    const loading = await this.overlayService.loading({
      message: "Carregando registros..."
    });
    return new Promise((resolve, reject) => {
      this.apiService.syncData(
        'sincroniza_processo_auditoria'
      ).subscribe(
        (retorno: ProcessoAuditoria[]) => {
          loading.dismiss();
          resolve(retorno);
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          );
          loading.dismiss();
          reject();
        }
      )
    });
  }

  async getAllProcessoAuditoriaUsuario(usuario_id: number): Promise<ProcessoAuditoria[]> {
    const loading = await this.overlayService.loading({
      message: "Carregando registros..."
    });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          usuario_id: usuario_id
        },
        'get_processo_auditoria_usuario'
      ).subscribe(
        (retorno: ProcessoAuditoria[]) => {
          loading.dismiss();
          resolve(retorno);
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      )
    });
  }

  async getProcessoAuditoria(processoAuditoriaId: number): Promise<ProcessoAuditoria> {
    const loading = await this.overlayService.loading({
      message: "Carregando registros..."
    });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          id: processoAuditoriaId
        },
        'get_processo_auditoria'
      ).subscribe(
        (retorno: ProcessoAuditoria) => {
          loading.dismiss();
          resolve(retorno);
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      )
    });
  }

  async getCTSProcessoAuditoria(processoAuditoriaId: number): Promise<CtsProcessoAuditoria[]> {
    const loading = await this.overlayService.loading({
      message: "Carregando registros..."
    });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          id: processoAuditoriaId
        },
        'get_teste_controle_processo'
      ).subscribe(
        (retorno: CtsProcessoAuditoria[]) => {
          loading.dismiss();
          resolve(retorno);
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      )
    });
  }

  async getInformacoesProcessoAuditoria(processoAuditoriaId: number): Promise<InfoProcessoAuditoria> {
    const loading = await this.overlayService.loading({
      message: "Carregando registros..."
    });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          id: processoAuditoriaId
        },
        'get_info_processo_auditoria'
      ).subscribe(
        (retorno: InfoProcessoAuditoria) => {
          loading.dismiss();
          resolve(retorno);
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      )
    });
  }

  async setCtsProcessoAuditoria(processoAuditoriaId: number, ctsProcesso: CtsProcessoAuditoria[]): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          id: processoAuditoriaId,
          cts_processo: ctsProcesso
        },
        "set_teste_controle_processo"
      ).subscribe(
        () => {
          resolve();
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      );
    });
  }

  async envioDadosProcessoAuditoria(processo: ProcessoAuditoria): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        processo,
        "set_processo_auditoria"
      ).subscribe(
        () => {
          resolve();
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      );
    });
  }

  async deletaProcessoAuditoria(processo: ProcessoAuditoria): Promise<void> {
    const loading = await this.overlayService.loading({
      message: "Apagando Auditoria..."
    });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        processo,
        "deleta_processo_auditoria"
      ).subscribe(
        async () => {
          loading.dismiss();
          resolve();
        },
        (erro) => {
          loading.dismiss();
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message;
          } else {
            msg = erro.message;
          };
          this.overlayService.toast(
            {
              message: msg,
              color: 'danger'
            }
          )
          reject();
        }
      )
    })
  }
}
