import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';
import { OverlayService } from 'src/app/core/services/overlay.service';
import { Arquivo } from '../auditoria-teste-controle-constatacao.service';

export interface AuditoriaTesteControleConstatacaoSolicitacaoInformacao {
  id: number;
  sigla: string;
  inexistente: boolean;
  status: string;
  status_desc: string;
  cor_status: string;
  observacao: string;
  observacao_cadastro: string;
  descricao: string;
  fileAnexo: File[];
  arquivo: Arquivo[];
  tipo: string;
  processo_descricao: string;
  data_limite_envio_solicitacao_informacao: string;
  resolicitacao_informacao_id: number;
  descricao_resolicitacao: string;
  finalizar_resolicitacoes: boolean;
  ct_auditado: boolean;
  ct_parcialmente_auditado: boolean;
}

export interface LogAlteracoesSolicitacoesInformacoes {
  sigla_ct: string;
  constatacao_solicitacao_informacao_id: number;
  descricao_solicitacao: string;
  constatacao_solicitacao_informacao_inexistente: string;
  status_atual: string;
  classe_status: string;
  usuarios_vinculados: string;
  historico_alteracoes: JSON;
}

export interface DashboardPizzaSolicitacaoInformacao {
  status_desc: string,
  status: string;
  background_color: string;
  border_color: string;
  point_background_color: string;
  processo_quantidade: JSON[];
  municipio_quantidade: JSON[];
}

@Injectable({
  providedIn: 'root'
})
export class TesteControleConstatacaoSolicitacaoInformacaoService {
  constructor(
    private apiService: ApiService,
    private overlayService: OverlayService
  ) { }

  getAllAuditoriaTesteControleConstatacaoSolicitacaoInfo(agencia_id: number, municipio_id: number, prestador_id: number, usuario_id: number, ano_id: number, processo_id: number): Observable<any> {
    return this.apiService.sendData(
      {
        agencia_id: agencia_id,
        municipio_id: municipio_id,
        prestador_id: prestador_id,
        usuario_id: usuario_id,
        ano_id: ano_id,
        processo_id: processo_id
      },
      "get_auditoria_constatacao_solicitacao_informacao"
    )
  }

  getDadosListagemPainel(processoAuditoriaId: number, statusDesc: string): Observable<AuditoriaTesteControleConstatacaoSolicitacaoInformacao[]> {
    return this.apiService.sendData(
      {
        processo_auditoria_id: processoAuditoriaId,
        status_desc: statusDesc
      },
      "get_auditoria_constatacao_solicitacao_informacao_painel"
    )
  }

  async enviaDados(auditoriaSolicitacaoInfo: AuditoriaTesteControleConstatacaoSolicitacaoInformacao): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.sendDataAndFile(
        auditoriaSolicitacaoInfo,
        auditoriaSolicitacaoInfo.fileAnexo,
        "set_auditoria_solicitacao_informacao"
      ).subscribe(
        (retorno) => {
          this.overlayService.toast({
            message: "Registro alterado com sucesso.",
            color: "success"
          });
          resolve();
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast({
            message: msg,
            color: "danger"
          })
          reject();
        }
      );
    })
  }

  async setResolicitacaoInformacao(auditoria_solicitacao_id: number, descricao: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          teste_controle_constatacao_solicitacao_informacao_id: auditoria_solicitacao_id,
          descricao_resolicitacao: descricao
        },
        "set_resolicitacao_informacao"
      ).subscribe(
        () => {
          this.overlayService.toast({
            message: "Solicitação enviada com sucesso.",
            color: "success"
          });
          resolve();
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast({
            message: msg,
            color: "danger"
          })
          reject();
        }
      );
    })
  }

  async cancelaResolicitacaoInformacao(resolicitacao_id: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          id: resolicitacao_id,
          ativa: false
        },
        "set_resolicitacao_informacao"
      ).subscribe(
        () => {
          this.overlayService.toast({
            message: "Solicitação cancelada com sucesso.",
            color: "success"
          });
          resolve();
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast({
            message: msg,
            color: "danger"
          })
          reject();
        }
      );
    })
  }

  async getLogAlteracoes(agencia_id: number, municipio_id: number, prestador_id: number, ano_id: number, teste_controle_id: number, processo_id: number, usuario_id: number): Promise<LogAlteracoesSolicitacoesInformacoes[]> {
    const loading = await this.overlayService.loading({ message: "Carregando registros..." });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          agencia_id: agencia_id,
          municipio_id: municipio_id,
          prestador_id: prestador_id,
          ano_id: ano_id,
          teste_controle_id: teste_controle_id,
          auditoria_processo_id: processo_id,
          usuario_id: usuario_id
        },
        "get_log_alteracoes_solicitacao_informacao"
      ).subscribe(
        (retorno: LogAlteracoesSolicitacoesInformacoes[]) => {
          loading.dismiss();
          resolve(retorno);
        },
        (erro) => {
          loading.dismiss();
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast({
            message: msg,
            color: "danger"
          })
          reject();
        }
      );
    })
  }

  async getDadosDashboardPizza(agencia_id: number, municipio_id: number, prestador_id: number, ano_id: number, status: string, tipo_visualizacao: string): Promise<DashboardPizzaSolicitacaoInformacao[]> {
    const loading = await this.overlayService.loading({ message: "Carregando registros..." });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          agencia_id: agencia_id,
          municipio_id: municipio_id,
          prestador_id: prestador_id,
          ano_id: ano_id,
          status: status,
          tipo_visualizacao: tipo_visualizacao
        },
        'get_dados_dashboard_solicitacao_informacao'
      ).subscribe(
        (retorno: DashboardPizzaSolicitacaoInformacao[]) => {
          loading.dismiss();
          resolve(retorno);
        },
        (erro) => {
          loading.dismiss();
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.overlayService.toast({
            message: msg,
            color: "danger"
          })
          reject();
        }
      )
    })
  }
}
