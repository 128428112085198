import { formatNumber } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuditoriaTesteSubstantivoConstatacao } from 'src/app/services/auditoria/teste-substantivo-constatacao.service';

@Component({
  selector: 'app-edita-registros',
  templateUrl: './edita-registros.page.html',
  styleUrls: ['./edita-registros.page.scss'],
})
export class EditaRegistrosPage implements OnInit {

  auditoria_teste_substantivo: AuditoriaTesteSubstantivoConstatacao = {} as AuditoriaTesteSubstantivoConstatacao;
  pontosAE: number = 0;

  constructor(
    private modalController: ModalController,
    private params: NavParams,
  ) { }

  ngOnInit() {
    this.auditoria_teste_substantivo = this.params.data.registro;
    this.setAcRegistro();
    this.calculaDesvioAE();
  }

  async closeModal() {
    await this.modalController.dismiss();
  }

  alterStyleModal(checked: boolean) {
    let modalInformacoes = document.body.querySelector('#modal_edicao_es');
    if (checked) {
      modalInformacoes.classList.add('modal-solicitacao-informacao-teste-controle-full-screen');
    } else {
      modalInformacoes.classList.remove('modal-solicitacao-informacao-teste-controle-full-screen');
    }
  }

  async confirmModal() {
    await this.modalController.dismiss(
      this.auditoria_teste_substantivo
    );
  }

  calculaDesvioAE(): void {
    if (this.auditoria_teste_substantivo.recalculo != null) {
      let valorDeclarado: number = parseFloat(this.auditoria_teste_substantivo.valores_digitados["valor_digitado"]);
      let valorRecalculado: number = parseFloat(this.auditoria_teste_substantivo.recalculo);
      let porcentagemDesvio: number = 0;
      porcentagemDesvio = ((valorRecalculado / valorDeclarado) - 1) * 100;
      porcentagemDesvio = Math.abs(porcentagemDesvio);
      this.auditoria_teste_substantivo.desvio = formatNumber(porcentagemDesvio, 'pt-pt', '1.4-4')
      this.pontosAE = 0;
      setTimeout(() => {
        if (porcentagemDesvio > 5) {
          this.pontosAE = 1;
        } else if (porcentagemDesvio <= 5 && porcentagemDesvio > 2) {
          this.pontosAE = 2;
        } else if (porcentagemDesvio <= 2 && porcentagemDesvio >= 0) {
          this.pontosAE = 3;
        }
      }, 100);
    }
  }

  private setAcRegistro(): void {
    if (this.auditoria_teste_substantivo.valores_digitados['porcentagem_ac'] < 50) {
      this.auditoria_teste_substantivo["ac"] = 1;
    } else if (this.auditoria_teste_substantivo.valores_digitados['porcentagem_ac'] >= 50 && this.auditoria_teste_substantivo.valores_digitados['porcentagem_ac'] <= 75) {
      this.auditoria_teste_substantivo["ac"] = 2;
    } else if (this.auditoria_teste_substantivo.valores_digitados['porcentagem_ac'] >= 75) {
      this.auditoria_teste_substantivo["ac"] = 3;
    } else {
      this.auditoria_teste_substantivo["ac"] = 0;
    }
  }

}
