import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-field-nota',
  templateUrl: './field-nota.component.html',
  styleUrls: ['./field-nota.component.scss'],
})
export class FieldNotaComponent implements OnInit {
  @Input() nota: number;
  @Input() rowData: any;

  arrayBuild = [];

  constructor() {
  }

  ngOnInit() {
    let valorReferencia;
    if (this.rowData != undefined && this.rowData != null) {
      valorReferencia = this.rowData.nota;
    } else {
      valorReferencia = this.nota;
    }
    if (valorReferencia == 1) {
      this.arrayBuild = [1];
    } else if (valorReferencia == 2) {
      this.arrayBuild = [1, 1];
    } else if (valorReferencia == 3) {
      this.arrayBuild = [1, 1, 1];
    } else {
      this.arrayBuild = [];
    }
  }

}
