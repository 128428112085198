import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../api/api.service";
import { Storage } from "@ionic/storage";
import { OverlayService } from "../../core/services/overlay.service";

export interface AuditoriaTesteSubstantivo {
  id: number;
  sigla: string;
  descricao: string;
  dados_relacionados: string;
  diante_parecer_precisa_realizar_teste: Boolean;
  avaliacao_confianca_exigida_nota_2: Boolean;
  avaliacao_confianca_exigida_nota_3: Boolean;
  diante_ac_independente_efetivo_realizar_teste: Boolean;
  informacoes_necessarias: string;
  observacao: string;
  evidencia_substantiva: any[];
}

const TABELA = "auditoria_teste_substantivo";

@Injectable({
  providedIn: 'root'
})
export class AuditoriaTesteSubstantivoService {
  loading: any;
  constructor(
    private storage: Storage,
    private apiService: ApiService,
    private overlayService: OverlayService,
    private router: Router
  ) { }

  getAuditoriaTesteSubstantivoById(id) {
    return this.storage.get(TABELA).then((registros) => {
      return registros.find((registro) => registro.id === parseInt(id));
    });
  }

  getAllAuditoriaTesteSubstantivo(): Promise<AuditoriaTesteSubstantivo[]> {
    return this.storage.get(TABELA);
  }

  async envioDadosAuditoriaTesteSubstantivo(registro: AuditoriaTesteSubstantivo): Promise<void> {
    const loading = await this.overlayService.loading({
      message: 'Salvando o registro...'
    });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        registro,
        'set_auditoria_teste_substantivo'
      ).subscribe(
        async () => {
          loading.dismiss();
          await this.sincronizaAuditoriaTesteSubstantivo();
          await this.overlayService.toast({
            message: "Registro inserido/alterado com sucesso.",
            color: "success"
          });
          this.router.navigate(["auditoria-teste-substantivo"], { skipLocationChange: true });
          resolve();
        },
        async (erro) => {
          loading.dismiss();
          let msg = "";
          if (erro.error != undefined) {
            msg = erro.error.message;
          } else {
            msg = erro.message;
          }
          await this.overlayService.toast({
            message: msg,
            color: 'danger'
          });
          reject();
        }
      )
    });
  }

  async sincronizaAuditoriaTesteSubstantivo(): Promise<void> {
    const loading = await this.overlayService.loading({
      message: 'Sincronizando teste substantivo...'
    });

    return new Promise((resolve, reject) => {
      this.apiService.syncData('sincroniza_auditoria_teste_substantivo').subscribe(
        async (retorno) => {
          if (retorno.length > 0) {
            await this.addAuditoriaTesteSubstantivoSync(retorno);
          }
          loading.dismiss();
          resolve();
        },
        async (erro) => {
          let msg = '';
          if (erro.error !== undefined) {
            msg = erro.error.message;
          } else {
            msg = erro.message;
          }
          loading.dismiss();
          await this.overlayService.toast({
            message: msg,
            color: 'danger'
          });
          reject();
        }
      );
    });
  }

  async addAuditoriaTesteSubstantivoSync(registros: AuditoriaTesteSubstantivo[]): Promise<any> {
    return this.storage.set(TABELA, registros);
  }
}
