import { Injectable } from '@angular/core';
import { saveAs as importedSaveAs } from "file-saver";

@Injectable({
  providedIn: 'root'
})
export class ExportaCsvService {

  constructor(
  ) { }

  exportaCsv(cabecalho: any[], valores: any[], nome_arquivo) {
    var csv: any = '';
    var line: any = '';
    cabecalho.forEach(
      (reg) => {
        line += reg.texto + ';'
      }
    )
    csv += line + '\r\n';
    valores.forEach(
      (linhaValor: any[]) => {
        line = '';
        linhaValor.forEach(
          (valor) => {
            line += valor.valor + ';';
          }
        );
        csv += line + '\r\n';
      }
    )
    this.saveCsv(csv, nome_arquivo)
  }

  saveCsv(csv, nome_arquivo) {
    var blob = new Blob([csv], { type: 'text/csv' });
    importedSaveAs(blob, nome_arquivo)
  }
}
