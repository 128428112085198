import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { ApiService } from '../api/api.service';
import { Observable } from 'rxjs';
import { Time } from '@angular/common';
import { OverlayService } from '../core/services/overlay.service';
import { StringMap } from '@angular/compiler/src/compiler_facade_interface';

export interface AuditoriaItemTesteControleConstatacao {
  id: number;
  auditoria_teste_controle_constatacao_id: string;
  atributo: string;
  nota_constatacao: string;
  constatacao: string;
  observacao: string;
  auditoria_status_constatacao_atributo_id: string;
  auditoria_status_constatacao_desc: string;
  status_cor: string;
  auditoria_teste_controle_id: string;
  sigla_ct: string;
  fileAnexo: File[];
  arquivo: Arquivo[];
  arquivo_evidencia: Arquivo[];
}

export interface AuditoriaItemTesteControleConstatacaoLog {
  id: number;
  constatacao: string;
  observacao: string;
  nota_constatacao: string;
  auditoria_status_constatacao_atributo_id: string;
  usuario_id: number;
  resolicitacao_auditor_id: number;
  data: Date;
  hora: Date;
}

export interface Arquivo {
  id: number;
  nome: string;
}

export interface AuditoriaRelatorioTesteControleDado {
  sigla_dado: string;
  descricao_dado: string;
  quantidade_maxima_nota: string;
  nota_dado: string;
}

export interface DadosCTDashboard {
  quantidade_auditado: number;
  atributos_auditados: JSON[];
  quantidade_nao_auditado: number;
  atributos_nao_auditados: JSON[];
}

export interface Infografico {
  teste_controle_sigla: string;
  teste_controle_descricao: string;
  processo_descricao: string;
  pilar: string;
  teste_controle_melhor_pratica_relacionada: string;
  objetivo_descricao: string;
  registros_constatacao: JSON[];
}

@Injectable({
  providedIn: 'root'
})
export class AuditoriaTesteControleConstatacaoService {

  constructor(
    private apiService: ApiService,
    private toastController: ToastController,
    private overlayService: OverlayService
  ) { }

  async envioDadosAuditoria(auditoriaItemTesteControleConstatacao: AuditoriaItemTesteControleConstatacao) {
    const envia_registro = async () => {
      this.apiService.sendDataAndFile(
        auditoriaItemTesteControleConstatacao,
        auditoriaItemTesteControleConstatacao.fileAnexo,
        "set_auditoria_teste_controle_constatacao"
      ).subscribe(
        (retorno) => {
          this.showMensage("Registro alterado com sucesso.", 'success');
        },
        (erro) => {
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.showMensage(msg, 'danger')
          return false;
        }
      );
    }
    await envia_registro();
  }

  getAllAuditoriaTesteControleConstatacao(
    agencia_id: number,
    municipio_id: number,
    prestador_id: number,
    usuario_id: number,
    ano_id: number,
    auditoria_teste_controle_id: number = undefined,
    exibirSomenteNaoAuditados: boolean = false,
    exibirSomenteComTodasSolicitacoesEntregues: boolean = false,
    exibirSomenteSemResolicitacao: boolean = false,
    exibirSomenteComSolicitacoesNaoSeAplica: boolean = false,): Observable<any> {
    return this.apiService.sendData(
      {
        agencia_id: agencia_id,
        municipio_id: municipio_id,
        prestador_id: prestador_id,
        usuario_id: usuario_id,
        ano_id: ano_id,
        auditoria_teste_controle_id: auditoria_teste_controle_id,
        exibir_somente_nao_auditados: exibirSomenteNaoAuditados,
        exibir_somente_com_todas_solicitacoes_entregues: exibirSomenteComTodasSolicitacoesEntregues,
        exibir_somente_sem_resolicitacao: exibirSomenteSemResolicitacao,
        exibir_somente_com_solicitacoes_nao_se_aplica: exibirSomenteComSolicitacoesNaoSeAplica,
      },
      "get_auditoria_teste_controle_constatacao"
    )
  }

  getDadosGeraisDashboard(agencia_id: number, municipio_id: number, prestador_id: number, ano_id: number, tipo_visualizacao: string, data_inicio: string, data_fim: string): Observable<any> {
    return this.apiService.sendData(
      {
        agencia_id: agencia_id,
        municipio_id: municipio_id,
        prestador_id: prestador_id,
        ano_id: ano_id,
        tipo_visualizacao: tipo_visualizacao,
        data_inicio: data_inicio,
        data_fim: data_fim,
      },
      "get_dados_gerais_dashboard"
    )
  }

  // Tipo exibicao A == Auditado N == Não auditado
  getDadosStatusDashboard(agencia_id: number, municipio_id: number, prestador_id: number, ano_id: number, atributo: string, tipo_visualizacao: string, data_inicio: string, data_fim: string, tipo_exibicao: string = undefined): Observable<any> {
    return this.apiService.sendData(
      {
        agencia_id: agencia_id,
        municipio_id: municipio_id,
        prestador_id: prestador_id,
        ano_id: ano_id,
        atributo: atributo,
        tipo_visualizacao: tipo_visualizacao,
        tipo_exibicao: tipo_exibicao,
        data_inicio: data_inicio,
        data_fim: data_fim,
      },
      "get_dados_status_dashboard"
    )
  }

  getAuditoriaArquivo(arquivo_id: number): Observable<any> {
    return this.apiService.getFile(
      {
        id: arquivo_id
      },
      "get_auditoria_arquivo"
    )
  }

  async deletaAuditoriaArquivoEvidencia(auditoria_item_teste_controle_constatacao_id: number, arquivo_id: number): Promise<void> {
    const loading = await this.overlayService.loading({ message: "Apagando arquivo..." });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          auditoria_item_teste_controle_constatacao_id: auditoria_item_teste_controle_constatacao_id,
          auditoria_arquivo_id: arquivo_id
        },
        "deleta_arquivo_evidencia_constatacao"
      ).subscribe(
        () => {
          loading.dismiss();
          resolve();
        },
        (erro) => {
          loading.dismiss();
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.showMensage("Erro : " + msg, 'danger');
          reject();
        }
      )
    })
  }

  async deletaAuditoriaArquivo(solicitacao_informacao_id: number, arquivo_id: number): Promise<void> {
    const loading = await this.overlayService.loading({ message: "Apagando arquivo..." });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          teste_controle_constatacao_solicitacao_informacao_id: solicitacao_informacao_id,
          auditoria_arquivo_id: arquivo_id
        },
        "deleta_arquivo_solicitacao_informacao"
      ).subscribe(
        () => {
          loading.dismiss();
          resolve();
        },
        (erro) => {
          loading.dismiss();
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.showMensage("Erro : " + msg, 'danger');
          reject();
        }
      )
    })
  }

  getRelatorioAuditoriaTesteControleDado(agencia_id: number, municipio_id: number, prestador_id: number, ano_id: number): Observable<any> {
    return this.apiService.sendData(
      {
        agencia_id: agencia_id,
        municipio_id: municipio_id,
        prestador_id: prestador_id,
        ano_id: ano_id,
      },
      "get_relatorio_auditoria_teste_controle_dado"
    )
  }

  getAuditoriaRegistroConstatacao(auditoria_item_teste_controle_constatacao_id: number): Observable<any> {
    return this.apiService.sendData(
      {
        auditoria_item_teste_controle_constatacao_id: auditoria_item_teste_controle_constatacao_id
      },
      "get_historico_auditoria_teste_controle_constatacao"
    )
  }

  async getRelatorioInfografico(agencia_id: number, municipio_id: number, prestador_id: number, ano_id: number, lista_teste_controle_id: number[]): Promise<Infografico[]> {
    const loading = await this.overlayService.loading({ message: "Carregando dados..." });
    return new Promise((resolve, reject) => {
      this.apiService.sendData(
        {
          agencia_id: agencia_id,
          municipio_id: municipio_id,
          prestador_id: prestador_id,
          ano_id: ano_id,
          lista_teste_controle_id: lista_teste_controle_id
        },
        'get_relatorio_infografico'
      ).subscribe(
        async (reg: Infografico[]) => {
          loading.dismiss();
          resolve(reg);
        },
        async (erro) => {
          loading.dismiss();
          let msg = '';
          if (erro.error != undefined) {
            msg = erro.error.message
          } else {
            msg = erro.message
          }
          this.showMensage("Erro : " + msg, 'danger');
          reject();
        }
      )
    })

  }


  private async showMensage(msg, color) {
    const toast = await this.toastController.create({
      message: msg,
      color: color,
      duration: 10000
    });
    toast.present();
  }
}
