import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuditoriaTesteControleConstatacaoSolicitacaoInformacao } from 'src/app/services/auditoria/teste-controle-constatacao-solicitacao-informacao.service';

@Component({
  selector: 'app-edita-registros',
  templateUrl: './edita-registros.page.html',
  styleUrls: ['./edita-registros.page.scss'],
})
export class EditaRegistrosPage implements OnInit {

  auditoria_solicitacao_informacao: AuditoriaTesteControleConstatacaoSolicitacaoInformacao = {} as AuditoriaTesteControleConstatacaoSolicitacaoInformacao;
  resolicitacaoId: number = null;
  resolicitacaoDescricao: string = null;

  constructor(
    private modalController: ModalController,
    private params: NavParams,
  ) { }

  async closeModal() {
    await this.modalController.dismiss();
  }

  setFileAnexo(ev) {
    this.auditoria_solicitacao_informacao.fileAnexo = ev.target.files;
  }

  async confirmModal() {
    await this.modalController.dismiss(
      this.auditoria_solicitacao_informacao
    );
  }

  setValStatus(ev) {
    if (ev.detail.checked) {
      this.auditoria_solicitacao_informacao.status = "T";
    }
  }


  ngOnInit() {
    this.auditoria_solicitacao_informacao = this.params.data.registro;
    this.resolicitacaoDescricao = this.params.data.descricao_resolicitacao;
    this.resolicitacaoId = this.params.data.resolicitacao_informacao_id;
  }

  alterStyleModal(checked: boolean) {
    let modalInformacoes = document.body.querySelector('#modal_edicao_si');
    if (checked) {
      modalInformacoes.classList.add('modal-solicitacao-informacao-teste-controle-full-screen');
    } else {
      modalInformacoes.classList.remove('modal-solicitacao-informacao-teste-controle-full-screen');
    }
  }

}
