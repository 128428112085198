import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AuditoriaStatusConstatacaoAtributo, AuditoriaStatusConstatacaoAtributoService } from 'src/app/services/auditoria-status-constatacao-atributo.service';
import { AuditoriaItemTesteControleConstatacao } from 'src/app/services/auditoria-teste-controle-constatacao.service';

@Component({
  selector: 'app-edita-registros',
  templateUrl: './edita-registros.page.html',
  styleUrls: ['./edita-registros.page.scss'],
})
export class EditaRegistrosPage implements OnInit {

  auditoria_item_constatacao: AuditoriaItemTesteControleConstatacao = {} as AuditoriaItemTesteControleConstatacao;
  statusConstatacao: AuditoriaStatusConstatacaoAtributo = {} as AuditoriaStatusConstatacaoAtributo;
  listaStatusConstatacao: AuditoriaStatusConstatacaoAtributo[] = [];
  usuarioAuditorInterno: Boolean = false;
  usuarioAuditorExterno: Boolean = false;
  podeAlterarStatus: Boolean = true;

  constructor(
    private modalController: ModalController,
    private params: NavParams,
    private statusconstatacaoService: AuditoriaStatusConstatacaoAtributoService,
  ) { }

  async closeModal() {
    await this.modalController.dismiss();
  }

  setFileAnexo(ev) {
    this.auditoria_item_constatacao.fileAnexo = ev.target.files;
  }

  async confirmModal() {
    if (typeof this.statusConstatacao != 'undefined') {
      this.auditoria_item_constatacao.auditoria_status_constatacao_atributo_id = this.statusConstatacao.id.toString();
      this.auditoria_item_constatacao.auditoria_status_constatacao_desc = this.statusConstatacao.status.toString();
      this.auditoria_item_constatacao.status_cor = this.statusConstatacao.cor.toString();
      if (this.statusConstatacao.nota != null) {
        this.auditoria_item_constatacao.nota_constatacao = this.statusConstatacao.nota.toString();
      } else {
        this.auditoria_item_constatacao.nota_constatacao = null;
      }
    };
    await this.modalController.dismiss(
      this.auditoria_item_constatacao
    );
  }

  setModeloConstatacao(ev) {
    if (ev.value.modelo_constatacao != null && this.auditoria_item_constatacao.constatacao == null) {
      this.auditoria_item_constatacao.constatacao = ev.value.modelo_constatacao
    }
  }

  ngOnInit() {
    this.auditoria_item_constatacao = this.params.data.registro;
    this.usuarioAuditorExterno = this.params.data.usuarioAuditorExterno;
    this.usuarioAuditorInterno = this.params.data.usuarioAuditorInterno;
    if (this.params.data.usuarioAuditorExterno && this.params.data.usuarioAuditorInterno) {
      this.statusconstatacaoService.getAllAuditoriaStatusConstatacaoAtributo().then(
        (registros: AuditoriaStatusConstatacaoAtributo[]) => {
          this.listaStatusConstatacao = registros;
        }
      );
    } else if (this.params.data.usuarioAuditorExterno) {
      this.statusconstatacaoService.getAuditoriaStatusConstatacaoAtributoAuditorExterno().then(
        (registros: AuditoriaStatusConstatacaoAtributo[]) => {
          this.listaStatusConstatacao = registros;
        }
      );
    } else if (this.params.data.usuarioAuditorInterno) {
      this.statusconstatacaoService.getAuditoriaStatusConstatacaoAtributoAuditorInterno().then(
        (registros: AuditoriaStatusConstatacaoAtributo[]) => {
          this.listaStatusConstatacao = registros;
        }
      );
    }
    else {
      this.statusconstatacaoService.getAuditoriaStatusConstatacaoAtributoNaoAuditor().then(
        (registros: AuditoriaStatusConstatacaoAtributo[]) => {
          this.listaStatusConstatacao = registros;
        }
      )
    }
    if (this.auditoria_item_constatacao.auditoria_status_constatacao_atributo_id != null) {
      this.statusconstatacaoService.getAuditoriaStatusConstatacaoAtributoById(this.auditoria_item_constatacao.auditoria_status_constatacao_atributo_id).then(
        (registro: AuditoriaStatusConstatacaoAtributo) => {
          // Caso o status seja setado pelo o auditor o usuário comum não poderar alterar
          if ((registro.somente_auditor_interno || registro.somente_auditor_externo) && !this.usuarioAuditorExterno && !this.usuarioAuditorInterno) {
            this.podeAlterarStatus = false;
          } else {
            this.podeAlterarStatus = true;
          }
          this.statusConstatacao = registro;
        }
      )
    } else {
      this.statusConstatacao = undefined;
    }
  }
}
